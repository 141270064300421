<!-- ui changes part -->
<div class="main-bg-color">
    <div class="container-fluid">
        <div class="row justify-content-end min-vh-100 align-items-center pt-5 pb-5">
            <div class="col-xl-4 col-lg-5 col-md-5">
                <div class="">
                    <form class="form mr-50" [formGroup]="login" novalidate>
                        <div class="white-box form-login p-30">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <img src="assets/images/Logo.svg" class="img-fluid" alt="logo" width="150px">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-center mb-4">
                                        <h3 class="mt-30 text-capitalize text-color fw_6">Sign In</h3>
                                        <h6 class="mt-10 text-capitalize">Enter your details to login to your account:
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="box-login">
                                <div class="row">
                                    <div class="col-md-12">
                                        <fieldset class="form-group ">
                                            <label class="form-lbl">Email <span class="required">*</span></label>
                                            <input type="text" formControlName="email" class="form-control"
                                                placeholder="Email" required>
                                            <mat-error *ngIf="isSubmitted && error('email','required')">Email Id is
                                                required.</mat-error>
                                        </fieldset>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <fieldset class="form-group">
                                            <label class="form-lbl">Password <span class="required">*</span></label>
                                            <input type="password" formControlName="password" class="form-control"
                                                placeholder="Password" required>
                                            <mat-error *ngIf="isSubmitted && error('password','required')">Password is
                                                required.</mat-error>
                                        </fieldset>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right">
                                            <a (click)="forgotPasswordNavigate()">Forget Password ?</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-md-12">
                                        <div class="login-btn text-center">
                                            <button class="button" (click)="onSubmit()" type="submit">Sign In
                                                <span
                                                    class="spinner-border loader-list text-black visually-hidden border-2"
                                                    *ngIf="loading"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="errorMsg" class="text-danger ">{{msg}}</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>