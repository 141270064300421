import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { LayOutService } from './lay-out.service';
import { GuestmessageService } from '../guest-dashboard/guestmessage/guestmessage.service';


@Component({
  selector: 'app-lay-out',
  templateUrl: './lay-out.component.html',
  // styleUrls: ['./lay-out.component.css']
})
export class LayOutComponent implements OnInit, OnDestroy {

  public _opened: boolean = true;

  public layout: any;
  user: any;
  letter: any;
  letter2: any;

  active: any;

  title: any;
  helpDesk = true;
  unsub: any;

  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger;
  
  public getToken: any = localStorage.getItem('token')
  public email = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];

  public GUID = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri'];
  // public role = localStorage.getItem('role');
  public role = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

  public isOptInOut: any = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent"];


  constructor(private _router: Router,
    public _dialog: MatDialog, private _layOutService: LayOutService,
    private _guestmessageService: GuestmessageService) {
    if (this.role == "Admin" || this.role == "HelpDesk") {
      this.staffNotificationPopup();
      this.guestNotificationPopup();
    }
  }



  accountNumber: any;
  firstName: any;

  staffNotification: any;
  guestNotification: any;

  hidden = false;
  isGuestEntry = false;
  isMobile = false;
  ngOnInit() {

    if (this.role == "Super Admin") {
      this.layout = true;
      this.user = localStorage.getItem('role');
      this.letter = this.user.charAt(0);
    }

    else if (this.role == "Admin") {
      this.layout = false;
      this._guestmessageService.startConnection();
      this._guestmessageService.addMessageListener(() => {
        this.staffNotificationPopup();
        this.guestNotificationPopup();
      });

      const hotelID = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
      this._layOutService.getUpdateProfileData(hotelID).subscribe((res: any) => {
        this.accountNumber = res.accountNumber;
        this.firstName = res.firstName;
        this.letter2 = this.firstName.charAt(0);
      });


      setTimeout(() => {
        this._layOutService.getTitle().subscribe(appTitle => this.title = appTitle);

      });

    }

    else if (this.role == "HelpDesk") {
      this.layout = false;
      this.helpDesk = false;
      this._guestmessageService.startConnection();
      this._guestmessageService.addMessageListener(() => {
        this.staffNotificationPopup();
        this.guestNotificationPopup();
      });

      const hotelID = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
      this._layOutService.getUpdateProfileData(hotelID).subscribe((res: any) => {
        this.accountNumber = res.accountNumber;
        this.firstName = res.firstName;
        this.letter2 = this.firstName.charAt(0);
      });


      setTimeout(() => {
        this._layOutService.getTitle().subscribe(appTitle => this.title = appTitle);

      });

    }
    setTimeout(() => {
      this.isMobile = window.innerWidth < 991 ? true : false;
      this.isGuestEntry = false;
      if (this._router.url.indexOf('/guestentry') > -1) {
        this.isGuestEntry = true;
      }
    }, 100);
  }


  staffNotificationPopup() {
    this._layOutService.getStaffNotification(this.GUID, this.email).subscribe((res: any) => {
      this.staffNotification = res;
    })
  }

  guestNotificationPopup() {
    this._layOutService.getGuestNotification(this.GUID, this.email).subscribe((res: any) => {
      this.guestNotification = res;
    })
  }



  logOut() {
    localStorage.clear();
    this._router.navigate(['login']);
  }

  public _toggleSidebar() {
    this._opened = !this._opened;

  }

  ngOnDestroy() {
    if (this.unsub) {
      clearInterval(this.unsub);
    }
  }

  mobileSideBarView() {
    $(".main-sidebar").toggleClass("menu-open");
  }

  redirectToPage() {
    this.mobileSideBarView();
    setTimeout(() => {
      this.isGuestEntry = false;
      if (this._router.url.indexOf('/guestentry') > -1) {
        this.isGuestEntry = true;
      }
    }, 100);
  }
}
