<!-- change password ui -->
<div class="app-wrapper">
  <!-- Page Data -->
  <div class="page-content-inner">
    <form [formGroup]="changePasswordForm" (ngSubmit)="postChangePassword(changePasswordForm.value)">
      <div class="row">
        <div class="col-md-12">
          <div class="dashboard-card mh-100-80">
            <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
              <div class="dashboard-card-title">
                <h5>Change Password</h5>
              </div>
            </div>
            <div class="dashboard-card-content m-auto" [ngClass]="isMobile == true ? 'w-80':'w-30'">
              <div class="row">
                <div class="example-container" class="">
                  <mat-form-field class="w-100" style="display: none;" appearance="outline">
                    <mat-label class="text-dark">Enter your email</mat-label>
                    <input matInput placeholder="pat@example.com" formControlName="email" required [(ngModel)]="email">
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <fieldset class="form-group">
                    <label class="form-lbl">Old Password <span class="text-danger">*</span></label>
                    <div class="headersearch">
                      <input type="Password" class="form-control" formControlName="oldPassword" required #oldPass
                        autocomplete="new-password" placeholder="Old Password">
                    </div>
                    <span *ngIf="errorMsg" class="text-danger changepassword_error">Incorrect Password</span>
                    <mat-error *ngIf="isSubmitted && error('oldPassword' ,'required')">Please enter old password.
                    </mat-error>
                  </fieldset>
                </div>

                <div class="col-md-12">
                  <fieldset class="form-group">
                    <label class="form-lbl">New Password <span class="text-danger">*</span></label>
                    <div class="headersearch">
                      <input type="Password" class="form-control" formControlName="newPassword" required
                        placeholder="New Password" #newPass>
                    </div>
                    <span *ngIf="notMatchPass" class="text-danger changepassword_error">New Passwords can't be same as
                      old password.</span>
                    <mat-error *ngIf="error('newPassword' ,'pattern')"> Password should have Minimum 8 Characters
                      atleast 1
                      uppercase 1 lowercase 1 special character 1 number.
                    </mat-error>
                    <mat-error *ngIf="isSubmitted && error('newPassword' ,'required')">Please enter new password.
                    </mat-error>
                  </fieldset>
                </div>

                <div class="col-md-12">
                  <fieldset class="form-group">
                    <label class="form-lbl">Confirm Password <span class="text-danger">*</span></label>
                    <div class="headersearch">
                      <input type="Password" class="form-control" formControlName="confirmNewPssword"
                        placeholder="Confirm Password" required>
                    </div>
                    <mat-error *ngIf="isSubmitted &&error('confirmNewPssword' ,'required')">Please enter confirm
                      password.</mat-error>
                    <mat-error *ngIf="error('confirmNewPssword', 'MustMatch')">Password must match.</mat-error>
                  </fieldset>
                </div>
                
              </div>
              <div class="col-md-12 text-center mt-20">
                <div class="d-flex justify-content-center align-items-center">
                  <button class="button" type="submit"> Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>