<div class="page-content-inner container-fluid pt-2">
    <div class="row">
        <div class="col-md-12">
            <div class="dashboard-card mh-100-80">
                <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
                    <div class="dashboard-card-title">
                        <h5>Permission Denied</h5>
                    </div>
                </div>
                <div class="dashboard-card-content ">
                    <div class="error-container">
                        <h2>Access Denied</h2>
                        <p>Sorry, you do not have permission to access this module.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>