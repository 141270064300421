 <div class="page-content-inner">
    <div class="row">
        <div class="col-md-12">
          <div class="dashboard-card mh-100-80">
            <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
              <div class="dashboard-card-title">
                <h5>Privacy Policy</h5>

            
            <form [formGroup]="termsPolicy" (ngSubmit)="accept()">
            <div class="col-md-12 mx-auto text-muted terms">
                <p>Welcome to www.GuestWindow.com provided by GuestWindow, Inc., doing business as GuestWindow
                    (“GuestWindow”). GuestWindow provides this Customer Privacy Policy (“Privacy Policy”) to inform you
                    about how GuestWindow collects, uses and discloses information provided by GuestWindow customers
                    (“Customers” or “you”) and by the consumers (“Consumers”) who place service requests with you.
                    GuestWindow takes your right to privacy seriously, and wants you to feel comfortable using
                    GuestWindow’s services. This privacy policy concerns information that may be collected by the Site,
                    Software, Device, or other aspect of the Services (as defined in the Terms of Use applicable to
                    you). This policy does not apply to other entities that are not owned or controlled by GuestWindow,
                    nor does it apply to persons that are not employees or agents of GuestWindow, or that are not under
                    GuestWindow’s control. This Privacy Policy is incorporated by reference into the GuestWindow Terms
                    of Use that are applicable to you (either the TOU for Monthly Customers or the TOU for Annual
                    Customers, depending on your method of registration with GuestWindow). GuestWindow expects you will
                    take time to read, and will abide by, the Terms of Use that are applicable to you. If you do not
                    wish to agree to GuestWindow’s Terms of Use and Privacy Policy, please refrain from using the
                    GuestWindow service. After reviewing the Privacy Policy, if you have any further questions, please
                    contact GuestWindow at support@guestwindow.com</p>

                <h4>A GENERAL NOTE ON INTERNET TECHNOLOGIES AND PRIVACY</h4>
                <p>Before you review this Privacy Policy, we recommend that you become familiar with the following
                    concepts and terms so that you can best understand how GuestWindow uses your data to provide the
                    Services.</p>
                <p>Ad Tags: Ad Tags are pieces of code that allow the delivery of online advertisements in the dedicated
                    ad spaces on websites and in online applications. Advertiser’s Ad Tags may transmit certain
                    anonymous information about the web site, section of the site and size of the ad.</p>

                <p>Cookies: Cookies are small text files that web servers typically send to users’ computer when they
                    visit a website. Cookies can be read or edited when the user loads a website or advertisement from
                    the domain that wrote the cookie in the first place. Cookies are used by companies to collect and
                    send information about a user’s website visit – for example, number of visits, average time spent,
                    pages viewed, navigation history through the website, and other statistics. This information can be
                    used to improve a user’s online experience by saving passwords, or allowing companies to track and
                    improve website loading times, for instance. Cookies can also be used to track a user’s browsing or
                    online purchasing habits and to target advertisements to specific users. Cookies cannot be used to
                    access any other data on a user’s hard drive, to personally identify them, or to act like malware or
                    a virus. Users who prefer not to accept cookies can set their Internet browser to <b> notify them
                        when they receive a cookie or to prevent cookies from being placed on their hard drive. You can
                        read more about cookies at http://www.allaboutcookies.org/cookies/ </b>
                </p>

                <p>
                    Personally Identifiable Information: We define personally identifiable information (or “PII”) as
                    “information that can be used to uniquely identify, contact, or precisely locate a single person or
                    can be used with other sources to uniquely identify a single individual” e. g. name, address, social
                    security number, e-mail address, telephone number, financial account number. This is the common
                    industry definition used by most Internet and Telecom companies. Information that is collected but
                    does not qualify as PII is called “Non-PII.”</p>

                <p>
                    Service: GuestWindow’s Service is a cloud based text messaging service wherein Consumers can send
                    and receive text messages (“Content”) to Customers, through a phone number provided by GuesWindow,
                    which Customer manages through the Software and/or Device.

                </p>

                <p>
                    Web Beacons & Pixels: A web beacon consists of a small string of code that represents a graphic
                    image request on a web page, a page in an online application, or in an email. A graphic image can be
                    associated with the web beacon, but is not required. When an image is present, usually the image is
                    designed to blend into the background of the web page, application, or email in which it appears.
                    Often a beacon will be placed by an online data collecting company without an image, so it is
                    invisible to the user, and in the smallest discrete space possible on the screen. This is a “pixel”
                    and is essentially an invisible web beacon. Pixels and web beacons can be used for site traffic
                    reporting, unique visitor counts, advertising auditing and reporting, personalization, and other
                    uses. They do this typically by writing or editing cookies on each user’s computer. Therefore, like
                    cookies, they cannot be used to key log, copy personal files from your computer, or replicate like a
                    virus. Most pixels and web beacons, including those used by Guest Window, collect only anonymous
                    data.

                </p>

                <h4>COLLECTION OF INFORMATION</h4>
                <p>
                    We collect certain information that you provide when using Guest Window’s Services. Our primary
                    goals in collecting information are to provide and improve our Services, including content
                    selections, to administer your account, and to enable users to enjoy and easily navigate the
                    Services. Registration for an account on this site requires a valid e-mail address, mobile phone
                    number, address, credit card number and a user name that has not been chosen already. You are not
                    required to provide any other information if you do not want to. Please be aware that the user name
                    you choose, the e-mail address you provide and any other information you enter may render you
                    personally identifiable, and may possibly be displayed on this web site intentionally (depending on
                    choices you make during the registration process, or depending on the way in which the site is
                    configured) or unintentionally (subsequent to a successful act of intrusion by a third party). As on
                    many web sites, GuestWindow may also automatically receive general information that is contained in
                    server log files, such as your IP address, and cookie information. Certain Non-Personally
                    Identifiable Information would be considered a part of your Personally Identifiable Information if
                    it were combined with other identifiers (for example, combining your zip code with your street
                    address) in a way that enables you to be identified. However, the same pieces of information are
                    considered Non-Personally Identifiable Information when they are taken alone or combined only with
                    other Non-Personally Identifiable Information (for example, your viewing preferences). We may
                    combine your Personally Identifiable Information with Non-Personally Identifiable Information and
                    aggregate it with information collected from other Customers and Consumers to attempt to provide you
                    with a better experience, to improve the quality of the Services and to analyze and understand how
                    our Services are used. We may also use the combined information without aggregating it to serve you
                    specifically, for instance to deliver a product to you according to your preferences or
                    restrictions.
                </p>

                <h4>USE OF INFORMATION</h4>

                <p>
                    We use the information we collect from our Services mainly as follows: (i) to provide, maintain,
                    protect and improve the Services, to develop new ones, and to protect GuestWindow and our users;
                    (ii) to respond to your submissions, questions, comments, requests and complaints and provide
                    customer service; (iii) as part of our efforts to keep the Services and integrations safe and
                    secure; (iv) to protect GuestWindow’s rights or property; (v) for internal operations, including
                    troubleshooting, data analysis, testing, research, customization, and improvements to service and
                    experience on the Site; (vi) to send you confirmations, updates, security alerts, and support and
                    administrative messages; and (vii) for any other purpose for which the information was collected. We
                    will ask for your consent before using information for a purpose other than those that are set out
                    in this Privacy Policy
                </p>

                <h4>SHARING INFORMATION WITH THIRD PARTIES</h4>
                <p>
                    GuestWindow will not share the Personally Identifiable Information we have collected from you,
                    except as follows: (i) where your consent is obtained, such as when you choose to opt-in or opt-out
                    of the sharing of data; (ii) where a service provided on our site requires interaction with a third
                    party, or is provided by a third party, such as a service provider (these service providers have
                    access to your Personally Identifiable Information only to perform services on our behalf and are
                    obligated not to disclose it or use it for any other purpose); (iv) when we believe in good faith
                    that we are lawfully authorized or required to do so or, including pursuant to legal action or law
                    enforcement; (v) to enforce or apply our privacy policy, our terms of use or our other policies or
                    agreements, or if it is deemed reasonably necessary by GuestWindow to protect GuestWindow’s legal
                    rights and/or property; or (vi) if GuestWindow is involved in a merger, acquisition or asset sale,
                    in which case we will continue to ensure the confidentiality of any Personally Identifiable
                    Information and give affected users notice before that information is transferred or becomes subject
                    to a different privacy policy. We may share aggregated, non-Personally Identifiable Information
                    publicly and with our partners – like publishers, advertisers or connected sites. For example, we
                    may share information publicly to show trends about the general use of our services. We are not
                    responsible for the actions of service providers or other third parties, nor are we responsible for
                    any additional information you provide directly to any third parties. In the event you choose to use
                    links displayed on this web site to visit other web sites, you are advised to read the privacy
                    policies published on those sites, as GuestWindow’s privacy policy does not apply to such sites and
                    GuestWindow is not responsible for the content or privacy and security practices and policies of
                    those sites. We will ask for your consent before using information for a purpose other than those
                    that are set out in this Privacy Policy
                </p>

                <h4>COOKIES</h4>
                <p>
                    Like many web sites, this web site sets and uses cookies to enhance your user experience — to
                    remember your personal settings, for instance. Advertisements may display on this web site and, if
                    so, may set and access cookies on your computer; such cookies are subject to the privacy policy of
                    the parties providing the advertisement. However, the parties providing the advertising do not have
                    access to this site’s cookies. These parties usually use non-personally-identifiable or anonymous
                    codes to obtain information about your visits to this site. You can visit the Network Advertising
                    Initiative if you want to find out more information about this practice, and to learn about your
                    options. Here is some general information about the cookie opt-out
                    http://www.worldprivacyforum.org/cookieoptout.html
                </p>

                <h4>GUESTWINDOW’S SECURITY & STORAGE PROCEDURES FOR YOUR DATA </h4>
                <p>
                    GuestWindow is conscious of the importance of your data to you and conscientious about protecting
                    that data from loss, theft, or misuse. GuestWindow collects data through its software platform and
                    stores data on a secured server, using the same security measures that we use to protect our own
                    proprietary corporate data. Only authorized users are able to access the data we collect and store.
                    While we cannot absolutely guarantee the security of the data we store, we do use all commercially
                    reasonable measures to protect this information. Please be aware, however, that despite our efforts,
                    no security measures are perfect or impenetrable and no method of data transmission can be
                    guaranteed against interception and/or misuse.
                </p>

                <h4>CUSTOMER SECURITY & STORAGE PROCEDURES FOR CONSUMER CONTENT</h4>
                <p>
                    You understand and agree to the importance of the data and content provided to you by Consumers
                    (“Consumer Content”). All of the Consumer Content collected by you through the Service and
                    accessible and printable through the Device shall be stored using the same security measures that
                    you use to protect your own proprietary corporate data. Only authorized users are able to access the
                    data you receive from Consumers. You shall use all commercially reasonable measures to protect this
                    information.
                </p>

                <h4>CHILDREN UNDER 13 YEARS OF AGE</h4>
                <p>
                    GuestWindow’s Service is not intended for persons under 13 years of age, and is not intentionally
                    developed for or directed to children. We do not knowingly solicit or collect any Personally
                    Identifiable Information from children under the age of 13, nor do we knowingly market our products
                    or services to children under the age of 13. If we learn that we have collected Personally
                    Identifiable Information of a child under 13, we will take steps to delete such information from our
                    files as soon as possible.
                </p>

                <h4>CHANGES TO GUESTWINDOW’S PRIVACY POLICY</h4>
                <p>
                    Any information that is collected is subject to the privacy policy in effect at the time such
                    information is collected. We may, however, modify and revise our privacy policy at any time. Any
                    changes and/or updates to the Privacy Policy will be updated directly on this page. We encourage you
                    to visit this page periodically to review our current Privacy Policy so that you will always know
                    what information we gather and how we may use that information. We will notify you about material
                    changes by posting them on GuestWindow’s site or by sending you an email or other notification, and
                    will indicate when such changes will become effective. By continuing to access or use GuestWindow
                    after those changes become effective, you are agreeing to be bound by the revised policy.
                </p>

                <h4>ACCESSING, EDITING OR DELETING YOUR INFORMATION</h4>
                <p>
                    At any time during your relationship with GuestWindow, you may access the content that you have
                    provided to GuestWindow, and you may edit the information you provided us that is associated with
                    your account by visiting your user account control panel. You may also request that your user
                    account be deleted; to do so, please contact GuestWindow using support@guestwindow.com, with a
                    request that we delete your information. We will take steps to delete your information as soon as is
                    practicable, but some information may remain in archived/backup copies for our records and as
                    otherwise required by law. Content or other data that you may have provided, and that is not stored
                    within your user account, such as articles published, may continue to remain on the site at
                    GuestWindow’s discretion, even after your user account is deleted. Please see the Terms of Use
                    applicable to you for more information. Upon termination of your relationship with GuestWindow,
                    GuestWindow cannot and will not migrate the data that was available to you during your relationship
                    with GuestWindow.
                </p>

                <h4>NO GUARANTEES</h4>
                <p>
                    While this privacy policy states standards for maintenance of data, and while efforts will be made
                    to meet the said standards, GuestWindow is not in a position to guarantee compliance with these
                    standards. There may be factors beyond GuestWindow’s control that may result in disclosure of data.
                    Consequently, GuestWindow offers no warranties or representations as regards maintenance or
                    non-disclosure of data.
                </p>

                <h4>CONTACTING US</h4>
                <p>
                    Should you have any questions in regards to this Privacy Policy, please contact our representatives
                    at the following email and they will be more than happy to clarify and assist you in any way
                    possible: support@guestwindow.com.
                </p>



            </div>


                       <br>

            <button   type="submit" class="button" >Accept</button>
        </form>
        </div>
        </div>
    </div>
    </div>

</div>
