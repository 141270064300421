<div class="page-content-inner container-fluid pt-2">
  <div class="row">
    <div class="col-md-12">
      <div class="dashboard-card mh-100-80">
        <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
          <div class="dashboard-card-title">
            <h5>Forgot Password</h5>
          </div>
        </div>
        <div class="dashboard-card-content w-30 m-auto">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="postForgotPassword()">
            <div class="row">
              <div class="col-md-12">
                <fieldset class="form-group">
                  <label class="form-lbl">Email <span class="text-danger">*</span></label>
                  <div class="headersearch">
                    <input type="email" class="form-control" placeholder="Email" formControlName="email"
                      (keypress)="omit_special_char_email($event)" required>
                  </div>
                  <mat-error *ngIf="error('email','pattern')">Please enter valid Email ID.</mat-error>
                  <mat-error *ngIf="isSubmitted && error('email' ,'required')">Email is required.</mat-error>
                </fieldset>
                <span *ngIf="errorMsg" class="text-danger">{{msg}}</span>
              </div>

            </div>
            <div class="col-md-12 text-center mt-20">
              <div class="d-flex justify-content-center align-items-center">
                <button class="button " type="submit"> Send</button>

              </div>
              <div class="d-flex justify-content-center align-items-center">
                <a class="fs_14 mt-10" (click)="cancel()">Cancel</a>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>