import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertError, onSweetAlertSuccess, onSweetAlertWarn } from 'src/app/optimisecode/optimisecode';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  smsTemplate: any;
  userEmail: any;
  adminEmail: any;
  load: any;
  isSubmitted = false;
  defaultCountry: string = 'us';
  public getToken: any = localStorage.getItem('token');
  isFeedBackSms!:boolean;

  constructor(private _fb: FormBuilder,
    private _accountService: MyAccountService,
    public _loaderService: LoaderService,
    private titleService: Title) { }

  updateProfile: any = this._fb.group({
    firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z\s]*$')]],
    lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z\s]*$')]],
    checkInTempleteId: ['', Validators.required],
    checkOutTempleteId: ['', Validators.required],
    preReservationTempleteId: ['', Validators.required],
    lessThan3FeedbackTempleteId:['',Validators.required],
    moreThan3FeedbackTempleteId:['',Validators.required],
    password: [''],
    accountNumber: [''],
    phoneNumber: ['', [Validators.pattern("^(?!0+$)[+0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
    countryCode: [''],
    countryNameCode: [''],
    contactInfo: ['', [Validators.required]],
    mobCountryCode: [],
    // authEmail: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    authEmail: ['',Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
    // authPassword: ['', [Validators.required]],
    authPassword: [''],
    // authPassword: ['', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}")]],
    // host: ['', Validators.required],
    host: [''],
    // port: [, [Validators.required, Validators.pattern("^[0-9]*$")]],
    port: [, [ Validators.pattern("^[0-9]*$")]],
    sslEnable: [false],
  });

  public Error = (controlName: string, errorName: string) => {
    return this.updateProfile.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    this.titleService.setTitle('Update Profile');

    if (this.updateProfile) {
      this.load = false;
    }
    else {
      this.load = this._loaderService.isLoading;
    }
    // Get email id from token //
    this.userEmail = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
    // Get hotel id from token //
    const hotelID = JSON.parse(window.atob(this.getToken.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    this.adminEmail = localStorage.getItem('emailRole');

    this._accountService.getUpdateProfileData(hotelID).subscribe((res: any) => {
      if (res) {
        this.load = false;
      }
      else {
        this.load = this._loaderService.isLoading;
      }
      this.isFeedBackSms = res.isFeedBackSms;
      this.updateProfile = this._fb.group({
        id: [res.id],
        firstName: [res.firstName, [Validators.required, Validators.pattern('^[a-zA-Z\s]*$')]],
        lastName: [res.lastName, [Validators.required, Validators.pattern('^[a-zA-Z\s]*$')]],
        checkInTempleteId: [res.checkInTempleteId, [Validators.required]],
        checkOutTempleteId: [res.checkOutTempleteId, [Validators.required]],
        preReservationTempleteId: [res.preReservationTempleteId, [Validators.required]],
        lessThan3FeedbackTempleteId:[res.lessThan3FeedbackTempleteId,Validators.required],
        moreThan3FeedbackTempleteId:[res.moreThan3FeedbackTempleteId,Validators.required],
        password: [res.password],
        accountNumber: [res.accountNumber],
        phoneNumber: [res.phoneNumber, [Validators.required, this.phoneNumberValidator.bind(this)]],
        countryCode: [res.countryCode],
        mobCountryCode: [res.mobCountryCode],
        mobCountryNameCode: [res.mobCountryNameCode],
        countryNameCode: [res.countryNameCode],
        contactInfo: [res.contactInfo, [Validators.required, this.phoneNumberValidator.bind(this)]],
        authEmail: [res.authEmail, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
        // authEmail: [res.authEmail, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        // authPassword: [res.authPassword, [Validators.required]],
        authPassword: [res.authPassword],
        // authPassword: [res.authPassword, [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}")]],
        host: [res.host],
        // host: [res.host, Validators.required],
        // port: [res.port, [Validators.required, Validators.pattern("^[0-9]*$")]],
        port: [res.port, [Validators.pattern("^[0-9]*$")]],
        sslEnable: [res.sslEnable],
      }, this.updateProfile.controls['accountNumber'].disable());
    })
    this.getSmsTemplateList();
  }

  getSmsTemplateList() {
    const email = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]
    this._accountService.getTemplateList(email).subscribe((res: any) => {
      this.smsTemplate = res;
    })
  }


  // Block special character//
  omit_special_char_email(event: any) {
    var k;
    k = event.charCode;
    return (k != 32)
  }

  //Block special character//
  omit_special_charKeyDown_valid_number(event: any) {
    var k;
    k = event.charCode;
    return k !== 69;
  }

  //Block special character//
  omit_special_char_valid_number(event: any) {
    var k;
    k = event.charCode;
    return (k >= 48 && k <= 57);
  }


  onSubmit() {
    this.isSubmitted = false;
    let phoneObj = this.updateProfile.value.contactInfo;
    let notificationPhoneObj = this.updateProfile.value.phoneNumber;
    let countryCode = phoneObj?.dialCode
    let notificationCountryCode = notificationPhoneObj?.dialCode;
    let countryNameCode = phoneObj?.countryCode;
    let NotificationCountryNameCode = notificationPhoneObj?.countryCode;
    let phone = phoneObj?.number;
    let notificationPhone = notificationPhoneObj?.number;
    this.updateProfile.get('countryNameCode').setValue(countryNameCode);
    this.updateProfile.get('countryCode').setValue(countryCode);
    this.updateProfile.get('mobCountryNameCode').setValue(NotificationCountryNameCode);
    this.updateProfile.get('mobCountryCode').setValue(notificationCountryCode);
    if(!this.updateProfile.value.port){
      this.updateProfile.get('port').setValue(0); 
    }
    
    if (this.updateProfile.invalid) {
      this.isSubmitted = true;
      return;
    }
    const phoneNumber = phone;
    const notificationPhoneNumber = notificationPhone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    const numericnotificationPhoneNumber = notificationPhoneNumber.replace(/\D/g, '');
    this.updateProfile.value.contactInfo = numericPhoneNumber;
    this.updateProfile.value.phoneNumber = numericnotificationPhoneNumber;

    this._accountService.updateProfileData(this.userEmail, this.updateProfile.value).subscribe((result: any) => {
      if (result.status == "success") {
        onSweetAlertSuccess(result);
      }
      else if(result.status == 'error' ||result.status == 'Error'){
          onSweetAlertError(result)
      }
      else if (result.status == "Already Exist") {
        onSweetAlertWarn(result);
      }
    })
  }

  // Validaiton for multiple country phone number //
  phoneNumberValidator(control: FormControl): { [key: string]: any } | null {
    const phoneNumber = control.value;
    const number = phoneNumber?.number;
    const countryCodes: CountryCode[] = [phoneNumber?.countryCode]; // Add more country codes as needed

    for (const countryCode of countryCodes) {
      try {
        const parsedNumber = parsePhoneNumberFromString(number, countryCode);
        if (parsedNumber && parsedNumber.isValid()) {
          return null; // Valid phone number
        }
      } catch (e) {
        // Parsing error, continue with the next country code
      }
    }
    return { invalidPhoneNumber: true }; // Invalid phone number for all country codes
  }
}
