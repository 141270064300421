import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const getToken: any = localStorage.getItem('token');
      const role: any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (localStorage.getItem('token') != null && localStorage.getItem('role') == "Super Admin") {
      return true;
    }
    else {
      if(role === 'Admin')
      this._router.navigate(['/permission-denied']);
      if(role==='Super Admin')
      this._router.navigate(['admin/permission-denied']);
      if(role==='HelpDesk')
      this._router.navigate(['/permission-denied']);
      return false;
    }
  }

}


