import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  public ManageTemplateList = environment.apiUrl + "api/Help/GetHelpList";
  public helpUrl = environment.apiUrl + "api/Help/AddHelp";
  constructor(public _http: HttpClient) { }

  getList(pageSize: number, pageIndex: number, search: string, sortColumn: string, sortOrder: string, fromDate: string, toDate: string) {
    if (fromDate == undefined || fromDate == null || fromDate == "") {
      fromDate = "";
    }
    if (toDate == undefined || toDate == null || toDate == "") {
      toDate = "";
    }
    return this._http.get(`${this.ManageTemplateList}?pageindex=${pageIndex}&pagesize=${pageSize}&search=${search}&sortColumn=${sortColumn}&sortDirection=${sortOrder}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  postHelp(obj: any) {
    return this._http.post(this.helpUrl, obj);
  }

}
