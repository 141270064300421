import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './angular-material/material/material.module';
import { LoginComponent } from './Account/login/login.component';
import { ChangePasswordComponent } from './Account/change-password/change-password.component';
import { ForgotPasswordComponent } from './Account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './Account/reset-password/reset-password.component';
import { MyAccountService } from './Account/myAccount.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidebarModule } from 'ng-sidebar';
import { LayOutComponent } from './lay-out/lay-out.component';
import { UpdateProfileComponent } from './Account/update-profile/update-profile.component';
import { InterceptorService } from './loader/interceptor.service';
import { TermsComponent } from './terms/terms.component';
import { ToastrModule } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { NgxMaskModule} from 'ngx-mask';
import { DataTablesModule } from 'angular-datatables';
import { HelpComponent } from './help/help.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FeedbackComponent } from './Account/feedback/feedback.component';
import { ErrorInterceptor } from './interceptor/http-error.interceptor';
import { PermissionDeniedComponent } from './Account/permission-denied/permission-denied.component';
import { GlobalErrorHandler } from './interceptor/GlobalErrorHandler';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LayOutComponent,
    UpdateProfileComponent,
    TermsComponent,
    HelpComponent,
    FeedbackComponent,
    PermissionDeniedComponent,
  

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    BrowserModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    DataTablesModule,
    NgxIntlTelInputModule
    

  ],
  providers: [MyAccountService, 
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },BnNgIdleService ],

  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
