

  <!-- change password ui -->
  <div class="app-wrapper">
    <!-- Page Data -->
    <div class="page-content-inner">
     <form [formGroup]="resetPasswordForm" (ngSubmit)="postResetPassword()">

     <div class="row">
         <div class="col-md-12">
             <div class="dashboard-card mh-100-80">
                 <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
                     <div class="dashboard-card-title">
                         <h5>Reset Password</h5>
                     </div>
                 </div>
                 <div class="dashboard-card-content w-30 m-auto">
                     <div class="row">
                          <div class="example-container" class="">
                            <mat-form-field class="w-100" style="display: none;" appearance="outline">
                              <mat-label class="text-dark">Enter your email</mat-label>
                              <input matInput placeholder="pat@example.com" formControlName="email" required [(ngModel)]="email">
                            </mat-form-field>
                          </div>

                          <div class="col-md-12">
                            <fieldset class="form-group">
                              <input type="hidden" id="hdnTocken" formControlName="token" [(ngModel)]="token" value="{{token}}">
                            </fieldset>
                          </div>

                         <div class="col-md-12">
                             <fieldset class="form-group">
                                 <label class="form-lbl">New Password <span class="text-danger">*</span></label>
                                 <div class="headersearch">
                                     <input type="password" class="form-control"  formControlName="newPassword" placeholder="New Password" required #newPass>
                                 </div>
                                </fieldset>
                                <mat-error *ngIf="isSubmitted && error('newPassword' ,'required')">Please enter new password.</mat-error>
                             <mat-error *ngIf="error('newPassword' ,'pattern')"> Password should have Minimum 8 Characters atleast 1
                               uppercase 1 lowercase 1 special character 1 number.
                             </mat-error>
                        </div>

                          <div class="col-md-12">
                           <fieldset class="form-group">
                               <label class="form-lbl">Confirm Password <span class="text-danger">*</span></label>
                               <div class="headersearch">
                                   <input type="password" class="form-control"  formControlName="confirmNewPassword" placeholder="Confirm Password" required>
                               </div>
                              </fieldset>
                              <mat-error *ngIf="isSubmitted && error('confirmNewPassword' ,'required')">Please enter confirm password.</mat-error>
                              <mat-error *ngIf="error('confirmNewPassword', 'MustMatch')">Password must match.</mat-error>
                           </div>
                     </div>

                     <div class="col-md-12 text-center mt-20">
                         <div class="d-flex justify-content-center align-items-center">
                             <button class="button" type="submit"> Save</button>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
   </form>
 </div>
</div>

