import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayOutService {

  public GetUpdateProfile = environment.apiUrl+"api/Hotel/UpdateProfileEdit?id=";

  public StaffNotification = environment.apiUrl+"api/Notification/GetNotificationList";

  public GuestNotification = environment.apiUrl+"api/Notification/GetGuestNotificationList";

  private title = new BehaviorSubject<String>('App title');
  private title$ = this.title.asObservable();

  constructor(public _http: HttpClient) { }

  setTitle(title: String) {
    this.title.next(title);
  }

  getTitle() {
    return this.title$;
  }

  getUpdateProfileData(id: any) {
    return this._http.get(`${this.GetUpdateProfile}${id}`);
  }

  getStaffNotification(GUID:any, email:any) {
    return this._http.get(`${this.StaffNotification}?guid=${GUID}&email=${email}`);
  }

  getGuestNotification(GUID:any, email:any) {

    return this._http.get(`${this.GuestNotification}?guid=${GUID}&email=${email}`);


  }




}
