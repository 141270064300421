import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivacyGuard implements CanActivate {
  constructor(private _router: Router ) {
   const getToken: any = localStorage.getItem('token');
   const terms:any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim"]

   }

  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const getToken: any = localStorage.getItem('token');
      const terms:any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim"];
      const role = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      
      if((role == 'Admin' || localStorage.getItem('role') == "Admin") && (terms == "True" || localStorage.getItem('terms') == 'True'))
      {
        return true;
      }
      if((role == 'HelpDesk' || localStorage.getItem('role') == "HelpDesk") && (terms == undefined || localStorage.getItem('terms') == undefined))
      {
        return true;
      }
      if((role == 'HelpDesk' || localStorage.getItem('role') == "HelpDesk") && (terms == '' || localStorage.getItem('terms') == ''))
      {
        return true;
      }
      if((role == 'HelpDesk' || localStorage.getItem('role') == "HelpDesk") && (terms == null || localStorage.getItem('terms') == null))
      {
        return true;
      }

    else {
      this._router.navigate(['/terms']);
      return false;
    }
  }

}
