import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  errorMsg: any;
  msg: any;
  isSubmitted = false;
  constructor(private _accountService: MyAccountService, private _router: Router,
    private titleService: Title) { }

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl()
  });

  public error = (control: string, error: string) => {
    return this.forgotPasswordForm.controls[control].hasError(error);
  }

  ngOnInit() {
    this.titleService.setTitle('Forgot Password');
  }

  postForgotPassword() {
    this.isSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    else {
      this._accountService.postForgotPassword(this.forgotPasswordForm.value.email).subscribe((res: any) => {

        if (res.status == "error") {
          this.errorMsg = true;
          this.msg = "Please Enter Valid Email";
        }
        else {
          localStorage.setItem('forgotPWD', JSON.stringify(res));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 10000

          }).then(() => {
            Swal.close()
          })
        }
      })
    }
  }

  cancel() {
    this._router.navigate(['login']);
  }

  //  Block space button //
  omit_special_char_email(event: any) {
    var k;
    k = event.charCode;
    return (k != 32)
  }


}
