<!-- update profile ui change part -->
<div class="app-wrapper">
    <div class="page-content-inner container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="dashboard-card mh-100-80">
                    <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
                        <div class="dashboard-card-title">
                            <h5>Update Profile</h5>
                        </div>
                    </div>
                    <div class="dashboard-card-content">
                        <form [formGroup]="updateProfile" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">First Name <span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" formControlName="firstName"
                                                required>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && Error('firstName', 'required')">FirstName is
                                            required.</mat-error>

                                        <mat-error *ngIf="Error('firstName', 'pattern')">First Name are not allowed
                                            space, special character and
                                            number.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Last Name <span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" formControlName="lastName" required>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && Error('lastName', 'required')">LastName is
                                            required.</mat-error>

                                        <mat-error *ngIf="Error('lastName', 'pattern')">Last Name are not allowed space,
                                            special character and number.
                                        </mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Contact Info <span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <ngx-intl-tel-input id="phoneNumber" [preferredCountries]="[defaultCountry]"
                                                [cssClass]="'form-control'" #phoneNumberInput [searchCountryFlag]="true"
                                                [maxLength]="15" [separateDialCode]="true" formControlName="contactInfo"
                                                class="w-100">
                                            </ngx-intl-tel-input>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && Error('contactInfo', 'required')">Phone Number
                                            is
                                            required.</mat-error>
                                        <mat-error
                                            *ngIf="updateProfile.value.contactInfo && updateProfile.controls['contactInfo'].errors">
                                            Invalid phone number for the selected country.
                                        </mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Notification Phone Number</label>
                                        <div class="headersearch">
                                            <ngx-intl-tel-input id="phoneNumber" [preferredCountries]="[defaultCountry]"
                                                [cssClass]="'form-control'" #phoneNumberInput [searchCountryFlag]="true"
                                                [maxLength]="15" [separateDialCode]="true" formControlName="phoneNumber"
                                                class="w-100">
                                            </ngx-intl-tel-input>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && Error('phoneNumber', 'required')">Phone Number
                                            is
                                            required.</mat-error>

                                        <mat-error
                                            *ngIf="updateProfile.value.phoneNumber && updateProfile.controls['phoneNumber'].errors">
                                            Invalid phone number for the selected country.
                                        </mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">CheckIn Template <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <select class="form-control" formControlName="checkInTempleteId" required>
                                                <option disabled>Select CheckIn Template</option>
                                                <option *ngFor="let template of smsTemplate" [value]="template.id">
                                                    {{template.title}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="Error('checkOutTempleteId', 'required')">Select Checkout
                                            Template.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">CheckOut Template <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <select class="form-control" formControlName="checkOutTempleteId" required>
                                                <option disabled>Select CheckOut Template</option>
                                                <option *ngFor="let template of smsTemplate" [value]="template.id">
                                                    {{template.title}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="Error('checkOutTempleteId', 'required')">Select Checkout
                                            Template.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl"> Pre Reservation Template <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <select class="form-control" formControlName="preReservationTempleteId"
                                                required>
                                                <option disabled>Select CheckOut Template</option>
                                                <option *ngFor="let template of smsTemplate" [value]="template.id">
                                                    {{template.title}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="Error('preReservationTempleteId', 'required')">Select Pre
                                            Reservation Template.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Password <span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="Password" class="form-control" formControlName="password"
                                                readonly required>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-md-6" *ngIf="isFeedBackSms">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Feedback Less Than 3 Template <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <select class="form-control" formControlName="lessThan3FeedbackTempleteId"
                                                required>
                                                <option disabled>Select Feedback Less Than 3 Template</option>
                                                <option *ngFor="let template of smsTemplate" [value]="template.id">
                                                    {{template.title}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="Error('lessThan3FeedbackTempleteId', 'required')">Select Feedback Less Than 3 Template.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6" *ngIf="isFeedBackSms">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Feedback More Than 3 Template <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <select class="form-control" formControlName="moreThan3FeedbackTempleteId"
                                                required>
                                                <option disabled>Select Feedback More Than 3 Template</option>
                                                <option *ngFor="let template of smsTemplate" [value]="template.id">
                                                    {{template.title}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="Error('moreThan3FeedbackTempleteId', 'required')">Select Feedback More Than 3 Template.</mat-error>
                                    </fieldset>
                                </div>
                                
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl ">Auth Email <span
                                                class="text-danger d-none">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" placeholder="Auth Email"
                                                formControlName="authEmail"
                                                (keypress)="omit_special_char_email($event)"
                                                pattern="^\S*[@]\S*[.]\S*$" >
                                        </div>
                                        <!-- <mat-error *ngIf="isSubmitted &&  Error('authEmail', 'required')">Auth Email
                                            is required.</mat-error> -->
                                        <mat-error *ngIf="Error('authEmail','pattern')">Please Enter Valid Auth
                                            Email Id.</mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl ">Auth Password <span
                                                class="text-danger d-none">*</span></label>
                                        <div class="headersearch">
                                            <input type="password" class="form-control" placeholder="Password"
                                                formControlName="authPassword" placeholder="Ex:Test@123"
                                                autocomplete="new-password"  >
                                        </div>
                                        <!-- <mat-error *ngIf="isSubmitted && Error('authPassword', 'required')">Auth
                                            Password is
                                            required.</mat-error> -->
                                        <!-- <mat-error *ngIf="Error('authPassword','pattern')" class="w-100 ">Auth
                                            Password
                                            should have Minimum 8 Characters
                                            atleast 1
                                            uppercase 1 lowercase 1 special character 1 number.</mat-error> -->
                                    </fieldset>
                                </div>

                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Port <span class="text-danger d-none">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" placeholder="Port" min="1"
                                                (keydown)="omit_special_charKeyDown_valid_number($event)"
                                                (keypress)="omit_special_char_valid_number($event)"
                                                formControlName="port" >
                                        </div>
                                        <!-- <mat-error *ngIf="isSubmitted && Error('port', 'required')">
                                            Port is required.</mat-error> -->
                                        <mat-error *ngIf="Error('port', 'pattern')">Please enter only
                                            digits, Not Allowed Decimal Value and
                                            Negative values.</mat-error>
                                    </fieldset>
                                </div>

                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Host <span class="text-danger d-none">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" placeholder="Host" min="1"
                                                formControlName="host" >
                                        </div>
                                        <!-- <mat-error *ngIf="isSubmitted && Error('host', 'required')">
                                            Host is required.</mat-error> -->
                                    </fieldset>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="box-switch">
                                            <label class="switch">
                                                <input type="checkbox" formControlName="sslEnable">
                                                <span class="slider"></span>
                                            </label>
                                            <span class="ml-2">SSL</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Account Number <span
                                                class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control " formControlName="accountNumber"
                                                readonly required>
                                        </div>
                                        <mat-error *ngIf="Error('accountNumber', 'required')">Account Number is
                                            required.</mat-error>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="col-md-12 text-center mt-20">
                                <div class="d-flex justify-content-center align-items-center">
                                    <button class="button "> Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--loading spinner code  -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">

    </mat-progress-spinner>

</div>