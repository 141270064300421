import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  public policy = environment.apiUrl+"api/GuestEntry/TermsCondition?email=";

  constructor(public _http: HttpClient) { }

  postPolicy(data: any ,email:any) {
    
    return this._http.post(this.policy + email, data);
  }


}
