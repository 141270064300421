import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LayOutService } from 'src/app/lay-out/lay-out.service';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide3 = true;
  email: any
  token: any = [];
  isMobile: any;
  public errorMsg = false;

  public msg = "";

  constructor(private _fb: FormBuilder, private _myAccountService: MyAccountService,
    private titleService: Title) { }

  isSubmitted = false;
  changePasswordForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    confirmNewPssword: ['', [Validators.required,]]
  },
    {
      validators: MustMatch('newPassword', 'confirmNewPssword')
    },
  );

  ngOnInit(): void {
    this.titleService.setTitle('Change Password');
    this.isMobile = window.innerWidth < 768 ? true : false;
    const getToken: any = localStorage.getItem('token');
    this.email = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
  }

  public error = (control: string, error: string) => {
    return this.changePasswordForm.controls[control].hasError(error);
  }

  notMatchPass: boolean = false
  postChangePassword(data: any) {
    this.isSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.changePasswordForm.value.oldPassword == this.changePasswordForm.value.newPassword) {
      this.notMatchPass = true;
      return
    }
    this._myAccountService.postChangePassword(data).subscribe({
      next: (res: any) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        }).then((res: any) => {
          if (res.isConfirmed) {
            location.reload()
          }

        })
      }, error: (err) => {
        this.errorMsg = true;
      }

    })
  }

}
// Validation for password and confirm password //
export function MustMatch(controlName: any, matchingControlName: any) {
  {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['MustMatch']) {
        return;
      }
      else if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}











