import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorLoggingService } from './ErrorLogging.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private _errorLogService:ErrorLoggingService){}

  handleError(error: any): void {
    console.error('An error occurred:', error);
    let statusCode = error.status | 0;
    let line = 0;
    let message = error.message;
    let componentName = '';
    let methodName = '';
    if(error)
    line = this._errorLogService.getLineNumber(error);
    componentName = this._errorLogService.getComponentName(error);
    methodName = this._errorLogService.getMethodName(error);
    this._errorLogService.onSendError(statusCode,line,message);
    // Handle the error or perform any necessary actions
  }

  
}