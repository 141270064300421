import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {

  private logUrl = environment.apiUrl + 'api/ErrorHandling/AddError';
  public getToken: any = localStorage.getItem('token');
  public email = this.getToken ? JSON.parse(window.atob(this.getToken.split('.')[1]))[
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
  ]:'';

  constructor(private _http: HttpClient) { }

  logError(error: any): void {
    // Perform logging operations here, such as sending the error to a server or storing it locally
    let statusCode = error.status;
    let line = 0;
    let component = '';
    let message = error.message;
    line = this.getLineNumber(error);
    this.onSendError(statusCode, line, message);

    // Additional logging operations can be implemented based on your requirements
  }

  sendError(hotel: string, code: number, line: number, message: string) {
    return this._http.post(`${this.logUrl}?hotel=${hotel}&code=${code}&line=${line}&message=${message}`, {});
  }

  onSendError(code: number, line: number, message: string) {
    this.sendError(this.email, code, line, message).subscribe((res) => {
    })
  }

  getLineNumber(error: Error): number {
    const stackTrace = error.stack || '';
    const match = stackTrace.match(/:\d+:\d+/);
    if (match) {
      const lineNumberString = match[0].substring(1);
      return parseInt(lineNumberString, 10);
    }
    return 0;
  }

  getComponentName(error: any): string {
    const stackTrace = error.stack || '';
    const match = stackTrace.match(/at\s+([\w\d_]+)\./);
    if (match) {
      return match[1];
    }
    return 'Unknown component';
  }

  getMethodName(error: Error): string {
    const stackTrace = error.stack || '';
    const methodName = stackTrace.match(/at\s+(\w+)\s/);
    if (methodName) {
      return methodName[1];
    }

    return 'Unknown method';
  }

}