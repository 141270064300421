import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { onSweetAlertSuccess, onSweetAlertWarn } from 'src/app/optimisecode/optimisecode';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  hotelName = ''
  rating: any = null;
  feedbackForm?: any;
  showForm: boolean = false;
  expireTitle = '';


  constructor(private formBuilder: FormBuilder, private _feedbackService: FeedbackService,
    private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Feedback');
    this.route.queryParams.subscribe((params: any) => {
      const hotelId = params.hotelid;
      const roomNo = params.roomno;
      const userId = params.userid;
      this.getHotelNameByHotelId(hotelId, userId);
      this.feedbackForm = this.formBuilder.group({
        guestId: [userId],
        hotelId: [hotelId],
        roomNo: [roomNo],
        rating: ['', Validators.required],
        comments: ['', Validators.required]
      });
    });
  }


  rate(stars: number) {
    this.rating = stars;
    this.feedbackForm.patchValue({ rating: this.rating });
  }

  getHotelNameByHotelId(hotelId: number, userId: number) {
    this._feedbackService.getHotelNameByHotelId(hotelId, userId)
      .subscribe((res: any) => {
        if (res.status === 'Success' || res.status === 'success')
          this.hotelName = res?.hotelName;
        else
          this.showForm = true;
        this.expireTitle = res.message;
      })
  }

  submitFeedback() {
    if (this.feedbackForm.invalid) {
      return;
    } else {
      this._feedbackService.submitFeedback(this.feedbackForm.value)
        .subscribe((res: any) => {
          if (res.status === 'success') {
            onSweetAlertSuccess(res)
              .then(res => res.isConfirmed ? window.location.reload() : null);
          } else {
            onSweetAlertWarn(res);
          }
          this.feedbackForm.reset();
          this.rating = 0;
        })
    }
  }

}
