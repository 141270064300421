import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private feedback = environment.apiUrl + 'api/Feedback/AddFeedback';
  private hotelName = environment.apiUrl + 'api/Hotel/GetHotelNameById';
  
  constructor(private _http: HttpClient) { }

  submitFeedback(data: any) {
    return this._http.post(this.feedback, data);
  }

  getHotelNameByHotelId(hotelId: number,guestId:number) {
    return this._http.get(`${this.hotelName}?hotelId=${hotelId}&guestId=${guestId}`);
  }
}
