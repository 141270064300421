import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { MatDialog } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'guestWindow';

  constructor(private bnIdle: BnNgIdleService ,private _router:Router) {

  }
  ngOnInit() {
    this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        localStorage.clear();
        this._router.navigate(['login']);
      }
    });
  }


}


