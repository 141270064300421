import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  hide2 = true;
  hide3 = true;
  email: any;
  token: any;
  isSubmitted = false;
  constructor(private _fb: FormBuilder, private _myAccountService: MyAccountService,
     private route: ActivatedRoute, private _router: Router,
     private titleService: Title) {

  }

  resetPasswordForm = this._fb.group({
    token: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    newPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    confirmNewPassword: ['', [Validators.required]]
  },
    {
      validators: MustMatch('newPassword', 'confirmNewPassword')
    }
  );
  public error = (control: string, error: string) => {
    return this.resetPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    // token get by url link //
    this.titleService.setTitle('Reset Password');
    this.route.queryParams.subscribe((params: any) => {

      this.email = params.email;
      this.token = params.token.replace(/ /g, "\+");

    })
  }

  cancel() {
    this._router.navigate(['forgotpassword'])
  }

  postResetPassword() {
    this.isSubmitted = true;
    if(this.resetPasswordForm.invalid){
      return
    }
    else {
      this._myAccountService.postResetPassword(this.resetPasswordForm.value).subscribe((res:any) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        }).then((res: any) => {
          if (res.isConfirmed) {
            this._router.navigate(['login']);
          }
        })
      })
  
    }

    }

}
export function MustMatch(controlName: any, matchingControlName: any) {
  {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['MustMatch']) {
        return;
      }
      else if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}
