import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptInOutGuard implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const getToken: any = localStorage.getItem('token');
    const isOptInOut: any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent"];

    if (isOptInOut && isOptInOut === 'True') {
      return true;
    } else {
      this._router.navigate(['/permission-denied']);
      return false;
    }

  }

}
