import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HelpService } from '../admin-dashboard/help/help.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  helpContent: any;
  getToken: any = localStorage.getItem('token');
  email = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
  name = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  isMobile: any;
  public errorMsg = false;


  constructor(private _fb: FormBuilder, private _help: HelpService, private titleService: Title) { }

  isSubmitted = false;
  helpForm = this._fb.group({
    hotelEmail: [this.email, [Validators.required, Validators.email]],
    hotelName: [this.name, [Validators.required]],
    message: ['', [Validators.required]],
  },


  );

  ngOnInit(): void {
    this.titleService.setTitle('Help');
    this.isMobile = window.innerWidth < 768 ? true : false;


  }

  public error = (control: string, error: string) => {
    return this.helpForm.controls[control].hasError(error);
  }

  postHelp(data: any) {
    this.isSubmitted = true;
    if (this.helpForm.invalid) {
      return;
    }

    this._help.postHelp(data).subscribe({
      next: (res: any) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        }).then((res: any) => {
          if (res.isConfirmed) {
            location.reload()
          }

        })
      }, error: (err) => {
        this.errorMsg = true;
      }

    })
  }
}



