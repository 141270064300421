import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { MyAccountService } from '../myAccount.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isSubmitted: boolean = false;
  public privacyPolicy = "Accept";

  public login: FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });

  constructor(private _accountService: MyAccountService, private _router: Router,
  public _loaderService: LoaderService,private titleService: Title) { }

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  hide = true
  public userData: any;
  public getToken: any;
  public errorMsg = false;
  public msg = "";
  loading = false;

  ngOnInit() {
    this.titleService.setTitle('Guest Window');
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.login.invalid) {
      return;
    }
    else {
      this.loading = true;
      this._accountService.postLogin(this.login.value).subscribe({
        next: (result: any) => {
          localStorage.setItem('token', JSON.stringify(result));
          const getToken: any = localStorage.getItem('token');
          const role = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
          localStorage.setItem('role', role);
          const terms: any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim"]
          localStorage.setItem('terms', terms);

          if (role == "Super Admin") {
            this._router.navigate(['admin/user']);
            this.loading = false;
          }
          else if (role == "Admin" && terms === "True") {
            this._router.navigate(['/guestentry']);
            this.loading = false;
          }
          else if (role == "Admin" && terms === undefined) {
            this._router.navigate(['/terms']);
            this.loading = false;
          }
          else if (role == "HelpDesk") {
            this._router.navigate(['/guestentry']);
            this.loading = false;
          }

        },
        error: (err) => {
          if (err.status == 0) {
            this.errorMsg = true;
            this.msg = "Email is not Valid.";
            this.loading = false;
          }
          else if (err.error.status == "Subscription days expired!" && err.status == 401) {
            this.errorMsg = true;
            this.msg = err.error.message;
            this.loading = false;
          }
          else if (err.status == 401 || err.status == 500) {
            this.errorMsg = true;
            this.msg = "Password is not Valid.";
            this.loading = false;
          }
          else if (err.status == 400) {
            this.errorMsg = true;
            this.msg = "Email & password is not Valid.";
            this.loading = false;
          }
          else if (err.status == 404) {
            this.errorMsg = true;
            this.msg = "Invalid Credential.";
            this.loading = false;
          }
        }
      });
    }

  }

  forgotPasswordNavigate() {
    this._router.navigate(['forgotpassword']);
  }


}
