import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';




@Injectable({
  providedIn: 'root'
})
export class GuestmessageService {

  getGuestListApi = environment.apiUrl + "api/GuestEntry/GetGuestWithStatusList";

  PostGuestChat = environment.apiUrl + "api/Sms/AddSms";

  GetGuestChat = environment.apiUrl + "api/Sms/GetUserWiseGuestData";
  autoSuggestion = environment.apiUrl + "api/Suggestions/AutoSuggestion?content=";
  public hubConnection?: HubConnection;

  constructor(private _http: HttpClient) { }
  //  Start connection hub //
  public startConnection(): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apiUrl + 'chathub',{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        withCredentials: true 
      })
      .build();
    this.hubConnection.start()
      .then(() => console.log('SignalR connection started.'))
      .catch(err => console.error('Error while starting SignalR connection:', err));
  }
  // call real time message method
  public addMessageListener(callback: () => void): void {
    this.hubConnection?.on('ReceiveMessage', callback);
  }

  getGuestList(search: any, email: any, pageSize: any, pageIndex: any) {

    return this._http.get(`${this.getGuestListApi}?PageSize=${pageSize}&PageIndex=${pageIndex}&Search=${search}&email=${email}`);

  }

  postGuestMsg(email: any, guestPhoneNumber: any, id: any, data: any) {
    return this._http.post(`${this.PostGuestChat}?email=${email}&ToSms=${guestPhoneNumber}&guestid=${id}`, data);
  }



  getGuestUserWiseData(email: any, ToSMS: any, id: any, timezone: any) {

    return this._http.get(`${this.GetGuestChat}?email=${email}&ToSms=${ToSMS}&guestid=${id}&timezone=${timezone}`);

  }


  getAutoSuggestions(content: string) {
    return this._http.get(this.autoSuggestion + content);
  }


}
