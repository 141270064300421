import Swal from "sweetalert2";

export function onSweetAlertSuccess(result: any) {
    return Swal.fire({
        position: 'center',
        text: result.message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertError(result: any) {
    return Swal.fire({
        position: 'center',
        text: result.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertWarn(result: any) {
    return Swal.fire({
        position: 'center',
        text: result.message,
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertError1(err: any) {
    return Swal.fire({
        position: 'center',
        text: err.error.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertDelete() {
    return Swal.fire({
        position: 'center',
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
    })
}

export function onSweetAlertDeactive() {
    return Swal.fire({
        position: 'center',
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, deactive it!',
        cancelButtonText: 'No, keep it',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
    })
}

export function onSweetAlertDeletedSuccess(result: any) {
    return Swal.fire({
        position: 'center',
        title: 'Deleted!',
        text: result.message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertCheckOutSuccess(result: any) {
    return Swal.fire({
        position: 'center',
        title: 'CheckOut!',
        text: result.message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}
export function onSweetAlertCheckInSuccess(result: any) {
    return Swal.fire({
        position: 'center',
        title: 'CheckIn!',
        text: result.message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertDeletedError(result: any) {
    return Swal.fire({
        position: 'center',
        title: 'Cancelled!',
        text: result.message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertDeletedWarning(result: any) {
    return Swal.fire({
        position: 'center',
        title: 'Warning!',
        text: result.message,
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
    });
}

export function onSweetAlertCheckOut() {
    return Swal.fire({
        position: 'center',
        title: 'Are you sure you want to checkout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Checkout it!',
        cancelButtonText: 'No, keep it',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
    })
}

