import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(private _http: HttpClient) { }

  public url = environment.apiUrl + "api/Authenticate/login";
  public changePasswordUrl = environment.apiUrl + "api/Authenticate/changepassword";
  public forgotPasswordApi = environment.apiUrl + "api/Authenticate/forgotpassword?email=";
  public resetpasswordApi = environment.apiUrl + "api/Authenticate/resetpassword";
  public ManageTemplateList = environment.apiUrl + "api/SmsTemplate/GetSmsTemplateList";
  public GetUpdateProfile = environment.apiUrl + "api/Hotel/UpdateProfileEdit?id=";
  public UpdateProfileData = environment.apiUrl + "api/Hotel/UpdateProfile?email="

  postLogin(data: any) {
    return this._http.post(this.url, data);
  }

  postChangePassword(obj: any) {
    return this._http.post(this.changePasswordUrl, obj);
  }

  postForgotPassword(email: string) {
    return this._http.post(this.forgotPasswordApi + email, email);
  }

  postResetPassword(data: any) {
    return this._http.post(this.resetpasswordApi, data);
  }

  getTemplateList(email: string) {
    return this._http.get(`${this.ManageTemplateList}?email=${email}`);
  }

  getUpdateProfileData(id: number) {
    return this._http.get(`${this.GetUpdateProfile}${id}`);
  }

  updateProfileData(email: string, data: any) {
    return this._http.put(`${this.UpdateProfileData}${email}`, data);
  }

}
