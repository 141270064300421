<!-- Help ui -->
<div class="app-wrapper">
    <!-- Page Data -->
    <div class="page-content-inner">
        <form [formGroup]="helpForm" (ngSubmit)="postHelp(helpForm.value)">
            <div class="row">
                <div class="col-md-12">
                    <div class="dashboard-card mh-100-80">
                        <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
                            <div class="dashboard-card-title">
                                <h5>Help</h5>
                            </div>
                        </div>
                        <div class="dashboard-card-content m-auto" [ngClass]="isMobile == true ? 'w-80':'w-30'">
                            <div class="row">
                                <div class="col-md-12">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Hotel Email<span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" formControlName="hotelEmail"
                                                required readonly>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && error('hotelEmail' ,'required')">Please enter
                                            hotel email.
                                        </mat-error>
                                    </fieldset>
                                </div>
                                <div class="col-md-12">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Hotel Name<span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <input type="text" class="form-control" formControlName="hotelName" required
                                                readonly>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && error('hotelName' ,'required')">Please enter
                                            hotel name.
                                        </mat-error>
                                    </fieldset>
                                </div>

                                <div class="col-md-12">
                                    <fieldset class="form-group">
                                        <label class="form-lbl">Message <span class="text-danger">*</span></label>
                                        <div class="headersearch">
                                            <textarea type="text" class="form-control" formControlName="message"
                                                required></textarea>
                                        </div>
                                        <mat-error *ngIf="isSubmitted && error('message' ,'required')">Please enter
                                            message.
                                        </mat-error>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="col-md-12 text-center mt-20">
                                <div class="d-flex justify-content-center align-items-center">
                                    <button class="button" type="submit">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>