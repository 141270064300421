import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './Account/change-password/change-password.component';
import { FeedbackComponent } from './Account/feedback/feedback.component';
import { ForgotPasswordComponent } from './Account/forgot-password/forgot-password.component';
import { LoginComponent } from './Account/login/login.component';
import { PermissionDeniedComponent } from './Account/permission-denied/permission-denied.component';
import { ResetPasswordComponent } from './Account/reset-password/reset-password.component';
import { UpdateProfileComponent } from './Account/update-profile/update-profile.component';
import { AdminGuard } from './Authentication-Guards/admin.guard';
import { HelpDeskGuard } from './Authentication-Guards/help-desk.guard';
import { PrivacyGuard } from './Authentication-Guards/privacy.guard';
import { UserGuard } from './Authentication-Guards/user.guard';
import { HelpComponent } from './help/help.component';
import { LayOutComponent } from './lay-out/lay-out.component';
import { TermsComponent } from './terms/terms.component';
import { OptModule } from './guest-dashboard/opt/opt.module';
import { OptInOutGuard } from './Authentication-Guards/opt-in-out.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: LayOutComponent, canActivate: [UserGuard], children: [
      { path: 'terms', component: TermsComponent },
      { path: 'guestentry', canActivate: [PrivacyGuard], loadChildren: () => import('./guest-dashboard/guest-entry/guest-entry.module').then(m => m.GuestEntryModule) },
      { path: 'guestmessage', canActivate: [PrivacyGuard], loadChildren: () => import('./guest-dashboard/guestmessage/guestmessage.module').then(m => m.GuestmessageModule) },
      { path: 'smsmarketing', canActivate: [PrivacyGuard, HelpDeskGuard], loadChildren: () => import('./guest-dashboard/sms-marketing/sms-marketing.module').then(m => m.SmsMarketingModule) },
      { path: 'emailmarketing', canActivate: [PrivacyGuard, HelpDeskGuard], loadChildren: () => import('./guest-dashboard/email-marketing/email-marketing.module').then(m => m.EmailMarketingModule) },
      { path: 'emailtemplate', canActivate: [PrivacyGuard, HelpDeskGuard], loadChildren: () => import('./guest-dashboard/email-template/email-template.module').then(m => m.EmailTemplateModule) },
      { path: 'smstemplate', canActivate: [PrivacyGuard, HelpDeskGuard], loadChildren: () => import('./guest-dashboard/sms-template/sms-template.module').then(m => m.SmsTemplateModule) },
      { path: 'groupmaster', canActivate: [PrivacyGuard], loadChildren: () => import('./guest-dashboard/group-master/group-master.module').then(m => m.GroupMasterModule) },
      { path: 'managestaff', canActivate: [PrivacyGuard], loadChildren: () => import('./staff-dashboard/manage-staff/manage-staff.module').then(m => m.ManageStaffModule) },
      { path: 'staffmessage', canActivate: [PrivacyGuard], loadChildren: () => import('./staff-dashboard/staff-message/staff-message.module').then(m => m.StaffMessageModule) },
      { path: 'opt', canActivate: [PrivacyGuard,OptInOutGuard], loadChildren: () => import('./guest-dashboard/opt/opt.module').then(m => m.OptModule) },
      { path: 'feed-back', canActivate: [PrivacyGuard], loadChildren: () => import('./guest-dashboard/feedback/feedback.module').then(m => m.FeedbackModule) },
      { path: 'changepassword', canActivate: [PrivacyGuard], component: ChangePasswordComponent },
      { path: 'updateprofile', canActivate: [PrivacyGuard], component: UpdateProfileComponent },
      { path: 'help', component: HelpComponent },
      { path: 'permission-denied', component: PermissionDeniedComponent },
    ]
  },
  {
    path: 'admin', component: LayOutComponent, canActivate: [AdminGuard], children: [
      { path: 'generalsetting', loadChildren: () => import('./admin-dashboard/general-setting/general-setting.module').then(m => m.GeneralSettingModule) },
      { path: 'user', loadChildren: () => import('./admin-dashboard/hotel/hotel.module').then(m => m.HotelModule) },
      { path: 'predefinedsmstemplate', loadChildren: () => import('./admin-dashboard/pre-defined-sms-template/pre-defined-sms-template.module').then(m => m.PreDefinedSmsTemplateModule) },
      { path: 'message-master', loadChildren: () => import('./admin-dashboard/message-master/message-master.module').then(m => m.MessageMasterModule) },
      { path: 'help', loadChildren: () => import('./admin-dashboard/help/help.module').then(m => m.HelpModule) },
      { path: 'error-log', loadChildren: () => import('./admin-dashboard/error-log/error-log.module').then(m => m.ErrorLogModule) },
      { path: 'sms-analysis', loadChildren: () => import('./admin-dashboard/sms-analysis/sms-analysis.module').then(m => m.SmsAnalysisModule) },
      { path: 'changepassword', component: ChangePasswordComponent },
      { path: 'permission-denied', component: PermissionDeniedComponent },
    ]
  },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'feedback', component: FeedbackComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
