<div class="page-content-inner">
    <div class="dashboard-card mh-100-80 position-relative">
        <div class="dashboard-card-header d-block d-md-flex align-items-center text-center">
            <div class="dashboard-card-title" *ngIf="!showForm">
                <h5>{{hotelName}} Feedback</h5>
            </div>
        </div>
        <div class="dashboard-card-content  m-auto" *ngIf="!showForm">
            <!-- feedback.component.html -->
            <br>
            <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">

                <label for="form-lbl">Rating</label>
                <div class="rating-stars ">
                    <i class="fa fa-star" (click)="rate(1)" [ngClass]="{ 'filled': rating >= 1 }" title="Worst"></i>
                    <i class="fa fa-star " (click)="rate(2)" [ngClass]="{ 'filled': rating >= 2 }" title="Bad"></i>
                    <i class="fa fa-star " (click)="rate(3)" [ngClass]="{ 'filled': rating >= 3 }" title="Good"></i>
                    <i class="fa fa-star " (click)="rate(4)" [ngClass]="{ 'filled': rating >= 4 }"
                        title="Very Good"></i>
                    <i class="fa fa-star" (click)="rate(5)" [ngClass]="{ 'filled': rating >= 5 }" title="Excellent"></i>
                </div>
                <div class="">
                    <fieldset class="form-group">
                        <label for="form-lbl">Comment</label>
                        <div class="headersearch">
                            <textarea id="comment" class="form-control" formControlName="comments"></textarea>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-12 text-center mt-20">
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="button" type="submit" [ngClass]="{'disabled-button':feedbackForm.invalid}">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
        
        <!-- Expire link message -->
        <div *ngIf="showForm">
            <div class="error-container">
                <p>{{expireTitle}}</p>
            </div>
        </div>
    </div>
</div>