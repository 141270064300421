import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TermsService } from './terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  public privacyPolicy = "Accept";

  termsPolicy: any = this._fb.group({
     terms:[true]
  })

  constructor(private _router: Router, private _termService: TermsService ,private _fb: FormBuilder) { }

  ngOnInit(): void {
    
    const getToken: any = localStorage.getItem('token');
    const terms = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim"];

    
  }

  accept() {
    const getToken: any = localStorage.getItem('token');
    const roleEmail = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
    this._termService.postPolicy(this.termsPolicy.value,roleEmail).subscribe((result:any) => {
      if(result.status == 'Success' || 'success'){
        let terms: any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim"]
        terms = this.termsPolicy.value.terms;
         terms = "True";
         localStorage.setItem('terms', terms)
          this._router.navigate(['/guestentry']);
      }
    })
  }


}
